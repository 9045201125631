<template>
  <div class="modal is-medium is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">Grupos de usuarios</p>
        <button class="delete" aria-label="close" type="reset" @click="close()"></button>
      </header>
      <section class="modal-card-body">
        <nav class="level" v-if="!showform">
          <div class="level-left"></div>
          <div class="level-left">
          </div>
          <div class="level-right">
            <div class="level-item">
              <button class="button is-info" @click="showFormAddGroup"> Agregar Grupo </button>
            </div>
          </div>
        </nav>
        <div class="field is-horizontal" v-else>
          <div class="field-label is-normal">
            <label class="label">Nombre</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model="groupName"
                  name="groupName"
                  :class="{ 'is-danger': vverrors.first('groupName') }"
                  v-validate="'required'"
                >
              </div>
            </div>
            <div class="field is-grouped">
              <div class="control">
                <button class="button is-success" :class="{ 'is-loading': loadingAdd }" @click="createGroup" :disabled="loadingAdd"><i class="material-icons">save</i></button>
              </div>
              <div class="control">
                <button class="button is-danger" @click="showFormAddGroup" :disabled="loadingAdd"><i class="material-icons">cancel</i></button>
              </div>
            </div>
          </div>
        </div>
         <base-table
          class="is-bordered is-striped is-narrow is-fullwidth is-hoverable bankingTable"
          :columns="columnsData"
          :data="userGroupsList"
          :loading=loading
        ></base-table>
      </section>
      <footer class="modal-card-foot">
        <div class="field is-grouped">
          <div class="control">
            <button class="button is-link is-light" :disabled="loading" @click="close()">Cerrar</button>
          </div>
        </div>
    </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalGroups',
  props: {
    close: {
      type: Function,
      default: () => {}
    },
    userGroupsList: {
      type: Array
    },
    createUserGroup: {
      type: Function
    },
    loadingAdd: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      groupName: null,
      showform: false,
      columnsData: [
        { id: 'id', header: 'Id', class: 'column-mobile' },
        { id: 'name', header: 'Nombre', class: 'column-mobile' },
        { id: 'active', header: 'Nombre', class: 'column-mobile', accessor: row => row.active ? 'Activo' : 'Inactivo' },
        { id: 'totalusers', header: '# de usuarios', class: 'column-mobile' }
      ]
    }
  },
  components: {
    BaseTable: () => import('@/components/Table')
  },
  methods: {
    async createGroup () {
      if (await this.$validator.validateAll()) {
        const response = await this.createUserGroup({ name: this.groupName })
        if (response) {
          this.groupName = null
          this.showFormAddGroup()
        }
      }
    },
    showFormAddGroup () {
      this.groupName = null
      this.showform = !this.showform
    }
  }
}
</script>
